



















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TokenSale extends Vue {
  information = [
    { title: 'Sale Price', value: '1 ARKN = $0.05' },
    { title: 'Sale Start Time (UTC)', value: 'October 21st 2021, 1:00 PM UTC' },
    { title: 'Sale End Time (UTC)', value: 'October 21st 2021, 2:30 PM UTC' },
    { title: 'Token Distribution (UTC)', value: 'October 21st 2021, 3:00 PM UTC' },
    { title: 'Initial Market Cap', value: 'TBA' },
    { title: 'Initial Token Circulation', value: 'TBA' },
  ]
}
